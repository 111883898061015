import React from "react";
import styled from "styled-components";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

const SliderContainer = styled.div`
  grid-column: 1 / span 12;
  background: ${(props) => props.theme.colors.white};
`;

const DotsContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-auto-flow: column;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 4rem;
  }
`;

const Dot = styled.button`
  border: none;
  min-width: 100%;
  height: 4px;
  background: ${(props) => props.theme.colors.grey};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.active {
    background: ${(props) => props.theme.colors.black};
  }
`;

const Slider: React.FC = ({ children }) => {
  const slideClassName = "keen-slider__slide";
  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [ref, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: 1,
    loop: true,
    rubberband: false,
    spacing: 32,
    breakpoints: {
      "(min-width: 768px)": {
        slidesPerView: 2,
      },
      "(min-width: 1024px)": {
        slidesPerView: 3,
      },
    },
    slideChanged(slide) {
      setCurrentSlide(slide.details().relativeSlide);
    },
  });

  return (
    <SliderContainer>
      <div ref={ref} className="keen-slider">
        {React.Children.map(children, (child: any) =>
          React.cloneElement(child, {
            className: `${child.props.className} ${slideClassName}`,
          })
        )}
      </div>
      {slider && (
        <DotsContainer>
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <Dot
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={"dot" + (currentSlide === idx ? " active" : "")}
              />
            );
          })}
        </DotsContainer>
      )}
    </SliderContainer>
  );
};

export default Slider;
