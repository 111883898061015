import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";

import LanguageProps from "../../interfaces/LanguageProps";

import { H2, H3 } from "../Text";

const FrequentlyAskedQuestionsContainer = styled.div`
  overflow: scroll;
  grid-column: 1/-1;
  overflow-x: auto;
  overflow-y: auto;
`;

const Title = styled(H2)`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`;

const QuestionSectionContainer = styled.div`
  display: grid;
  gap: 1rem 0;
  align-content: flex-start;
`;

interface QuestionSectionProps {
  className?: string;
  question?: string;
  answer?: string;
}

const QuestionSection: React.FC<QuestionSectionProps> = ({
  className,
  question,
  answer,
}) => (
  <QuestionSectionContainer className={className}>
    <H3>{question}</H3>
    <p>{answer}</p>
  </QuestionSectionContainer>
);

const InnerContainer = styled.div`
  grid-column: 1 / span 12;
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

interface FrequentlyAskedQuestionsProps extends LanguageProps {
  className?: string;
  title?: string;
}

const FrequentlyAskedQuestions: React.FC<FrequentlyAskedQuestionsProps> = ({
  language,
  className,
  title,
}) => {
  const isEnglish = language === "en";

  const data = useStaticQuery(graphql`
    query AllFrequentlyAskedQuestionsQuery {
      faq: allStrapiFrequentlyAskedQuestion {
        nodes {
          id
          dutchQuestion
          dutchAnswer
          englishQuestion
          englishAnswer
        }
      }
    }
  `);

  const frequentlyAskedQuestions = data.faq.nodes;

  return (
    <FrequentlyAskedQuestionsContainer className={className}>
      {title && <Title>{title}</Title>}
      <InnerContainer>
        {frequentlyAskedQuestions.map((question: any) => {
          const {
            id,
            englishQuestion,
            englishAnswer,
            dutchQuestion,
            dutchAnswer,
          } = question;

          return (
            <QuestionSection
              key={id}
              question={isEnglish ? englishQuestion : dutchQuestion}
              answer={isEnglish ? englishAnswer : dutchAnswer}
            />
          );
        })}
      </InnerContainer>
    </FrequentlyAskedQuestionsContainer>
  );
};

export default FrequentlyAskedQuestions;
