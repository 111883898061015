import styled from "styled-components";

import { GridContainer } from "../Containers";

const HomeContainer = styled(GridContainer)`
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 4rem 0;
  }
`;

export default HomeContainer;
