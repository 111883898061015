import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { H4 } from "../Text";
import { CoverImage } from "../../models";
import { BaseButton } from "../Buttons";

import ArrowRight from "../../svg/arrow-right.svg";

const SlideContainer = styled(Link)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  background: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem;
`;

const ContentContainer = styled.div`
  grid-column: 1 / span 12;
`;

const DetailsContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BuyButton = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.bavakRed};
  padding: 1rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

interface SlideProps {
  className?: string;
  name: string;
  slug: string;
  coverImage: CoverImage;
}

const Slide: React.FC<SlideProps> = ({ className, name, slug, coverImage }) => {
  const gatsbyImageData = getImage(coverImage.localFile);

  return (
    <SlideContainer to={slug} className={className}>
      <ContentContainer>
        {gatsbyImageData && <GatsbyImage image={gatsbyImageData} alt={name} />}
        <DetailsContainer>
          <H4>{name}</H4>
          <BuyButton>
            <ArrowRight />
          </BuyButton>
        </DetailsContainer>
      </ContentContainer>
    </SlideContainer>
  );
};

export default Slide;
