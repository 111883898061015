import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";

import { ProductSlide, Slider } from "../Slider";
import { H2 } from "../Text";

import getCurrentLocale from "../../utils/getCurrentLocale";

const Container = styled.div`
  grid-column: 1/-1;
`;

const Title = styled(H2)`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`;

interface FeaturedProductsProps {
  title?: string;
  className?: string;
}

const FeaturedProducts: React.FC<FeaturedProductsProps> = ({
  title,
  className,
}) => {
  const data = useStaticQuery(graphql`
    query FeaturedProductsQuery {
      featuredProducts: allStrapiProduct(limit: 8) {
        nodes {
          slug
          id: productId
          dutchName: dutchName
          englishName: englishName
          price
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          productCategory {
            slug
            dutchName: dutchName
            englishName: englishName
          }
        }
      }
    }
  `);

  const isEnglish = getCurrentLocale() === "en";
  const featuredProducts = data.featuredProducts.nodes;

  return (
    <Container className={className}>
      {title && <Title>{title}</Title>}
      <Slider>
        {featuredProducts.map((featuredProduct: any) => {
          const {
            id,
            slug: productSlug,
            dutchName,
            englishName,
            coverImage,
            productCategory: { slug: productCategorySlug },
          } = featuredProduct;

          return (
            <ProductSlide
              key={id}
              slug={
                isEnglish
                  ? `/en/${productCategorySlug}/${productSlug}/`
                  : `/${productCategorySlug}/${productSlug}/`
              }
              name={isEnglish ? `${englishName}` : `${dutchName}`}
              coverImage={coverImage}
            />
          );
        })}
      </Slider>
    </Container>
  );
};

export default FeaturedProducts;
