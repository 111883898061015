import React from "react";
import styled from "styled-components";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import { H1 } from "../Text";
import { PrimaryButton } from "../Buttons";

const HeroContainer = styled(BgImage)`
  position: relative;
  min-height: 22rem;
  display: grid;
  align-items: center;
  padding: 4rem 2rem;
  background-color: ${({ theme }) => theme.colors.black};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    min-height: 26rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    min-height: 28rem;
    padding: 4rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    min-height: 32rem;
  }
`;

const Title = styled(H1)`
  color: ${({ theme }) => theme.colors.white};
  word-break: break-word;
  max-width: 12ch;
`;

const ShopButton = styled(PrimaryButton)`
  margin-top: 2rem;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
`;

interface ProductHeroProps {
  className?: string;
  title: string;
  imageData: ImageDataLike;
  buttonText?: string;
  onButtonClick?: () => void;
}

const ProductHero: React.FC<ProductHeroProps> = ({
  title,
  imageData,
  buttonText,
  onButtonClick,
}) => {
  return (
    <HeroContainer image={getImage(imageData)}>
      <div>
        <Title>{title}</Title>
        {buttonText && onButtonClick && (
          <ShopButton onClick={onButtonClick}>{buttonText}</ShopButton>
        )}
      </div>
    </HeroContainer>
  );
};

export default ProductHero;
