import React from "react";
import styled from "styled-components";

const Container = styled.div`
  grid-column: 1/-1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 8;
  }
`;

interface IntroductionProps {
  className?: string;
}

const Introduction: React.FC<IntroductionProps> = ({ className, children }) => (
  <Container className={className}>{children}</Container>
);

export default Introduction;
